import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-stamford-connecticut.png'
import image0 from "../../images/cities/scale-model-of-welcome-to-connecticut-sign-in-stamford-connecticut.png"
import image1 from "../../images/cities/scale-model-of-ukrainian-museum-&-library-in-stamford-connecticut.png"
import image2 from "../../images/cities/scale-model-of-putnam-cottage-in-stamford-connecticut.png"
import image3 from "../../images/cities/scale-model-of-the-glass-house-in-stamford-connecticut.png"
import image4 from "../../images/cities/scale-model-of-pear-tree-point-beach-in-stamford-connecticut.png"
import image5 from "../../images/cities/scale-model-of-old-town-hall-in-stamford-connecticut.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Stamford'
            state='Connecticut'
            image={image}
            lat='41.0534302'
            lon='-73.5387341'
            attractions={ [{"name": "Welcome to Connecticut Sign", "vicinity": "Greenwich", "types": ["point_of_interest", "establishment"], "lat": 41.013304, "lng": -73.65404089999998}, {"name": "Ukrainian Museum & Library", "vicinity": "161 Glenbrook Rd, Stamford", "types": ["library", "museum", "point_of_interest", "establishment"], "lat": 41.0611629, "lng": -73.52527079999999}, {"name": "Putnam Cottage", "vicinity": "243 E Putnam Ave, Greenwich", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.036864, "lng": -73.6190722}, {"name": "The Glass House", "vicinity": "199 Elm St, New Canaan", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.1464339, "lng": -73.4967868}, {"name": "Pear Tree Point Beach", "vicinity": "Pear Tree Point Rd, Darien", "types": ["natural_feature", "point_of_interest", "establishment"], "lat": 41.0453747, "lng": -73.48373170000002}, {"name": "Old Town Hall", "vicinity": "Greenwich Ave, Greenwich", "types": ["point_of_interest", "establishment"], "lat": 41.0251728, "lng": -73.6253064}] }
            attractionImages={ {"Welcome to Connecticut Sign":image0,"Ukrainian Museum & Library":image1,"Putnam Cottage":image2,"The Glass House":image3,"Pear Tree Point Beach":image4,"Old Town Hall":image5,} }
       />);
  }
}